import { Button, Empty } from "antd";
import { replaceUnderScore } from "components/pages/transcations-flow/utils/reuseableUtils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchUserNotifications,
  updateUserNotifReadStatus,
} from "redux/actions/actions";
import styled from "styled-components";
import { NotificationItem } from "./NotificationItem";

export const NotificationsList = ({ tab }: { tab: "all" | "unread" }) => {
  const data = useSelector((state: any) => state.notifications);
  const [noti, setNoti] = useState<any[]>([]);
  const hasData = noti?.length > 0;
  const [numToShow, setNumberToShow] = useState(20);
  const [updating, setUpdating] = useState({
    id: "",
    state: false,
  });

  useEffect(() => {
    const unreadData = data.filter(
      (item: any) => item?.status?.toLowerCase() === "unread"
    );
    if (tab === "unread") {
      setNoti(unreadData);
    } else {
      setNoti(data);
    }
  }, [data, tab]);

  const markNotiAsRead = (id: string) => {
    setUpdating({ id, state: true });
    updateUserNotifReadStatus(id, () => {
      fetchUserNotifications(200);
      setUpdating((prev) => ({ ...prev, state: false }));
    });
  };

  return (
    <NotificationsListStyled>
      {!hasData ? (
        <>
          <br />
          <Empty description="No notification available!" />
        </>
      ) : (
        noti
          ?.slice(0, numToShow)
          ?.map((item: any) => (
            <NotificationItem
              key={item.id}
              id={item.id}
              title={replaceUnderScore(item.type)}
              message={item.message}
              state={item.status?.toLowerCase()}
              date={item.dateCreated}
              onReadClick={markNotiAsRead}
              loading={item.id === updating.id && updating.state}
            />
          ))
      )}
      {hasData && numToShow <= noti.length && (
        <Button onClick={() => setNumberToShow(numToShow + 20)}>
          Load more
        </Button>
      )}
    </NotificationsListStyled>
  );
};

const NotificationsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
