import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LOADING } from "redux/actionTypes";
import {
  getRecipientById,
  useIsRecipientFirstTime,
  useUpdateIsRecipientFirstTime,
} from "../paymentHelper";

const useRecipientConfirmation = (transfer: any, callback: () => void) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { recipientId, id: transferId } = transfer || {};
  const recipientData = getRecipientById(recipientId);

  const { data, isLoading, isError, refetch, error } =
    useIsRecipientFirstTime(recipientId);
  const { mutate: updateRecipientFisrtTime, isLoading: isUpadating } =
    useUpdateIsRecipientFirstTime(() => {
      setShowModal(false);
      callback();
    });

  useEffect(() => {
    dispatch({ type: LOADING, payload: isLoading });
  }, [isLoading]);

  const { isFirstTime } = data || {};
  const isRecipientFirstTransfer = data && isFirstTime;

  const handleConfirm = () => {
    const payload = {
      transferId: transferId,
      recipientId: recipientId,
      acknowledgment: true,
    };

    updateRecipientFisrtTime(payload);
  };

  const triggerConfirmation = () => {
    if (isError) {
      refetch();
    } else if (isRecipientFirstTransfer) {
      setShowModal(true);
    } else {
      callback();
    }
  };

  return {
    showModal,
    setShowModal,
    handleConfirm,
    recipientData,
    triggerConfirmation,
    isUpadating,
  };
};

export default useRecipientConfirmation;
