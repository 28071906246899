import { Tabs } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Breakpoint } from "../../utils/stylesVariables";
import { NotificationsList } from "./components/NotificationsList";

const tabsChildren = (unreadCount: number) => [
  {
    key: "all",
    label: "All",
    children: <NotificationsList tab="all" />,
  },
  {
    key: "unread",
    label: `Unread (${unreadCount})`,
    children: <NotificationsList tab="unread" />,
  },
];

const Notifications = () => {
  const data = useSelector((state: any) => state.notifications);
  const totalUnreadNoti =
    data.filter((item: any) => item?.status?.toLowerCase() === "unread")
      ?.length || 0;

  return (
    <NotificationsStyled>
      <ContentWarpper>
        <Tabs
          className="_tab"
          items={tabsChildren(totalUnreadNoti)}
          type="card"
        />
      </ContentWarpper>
    </NotificationsStyled>
  );
};

export default Notifications;

const NotificationsStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContentWarpper = styled.div`
  width: 100%;
  max-width: 750px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 48px 0px;

  @media (max-width: ${Breakpoint.md}) {
    padding: 32px 0px;
  }
  ._tab {
    width: 100%;
    .ant-tabs-nav-list {
      width: 100%;
      justify-content: center;
    }
    .ant-tabs-tab {
      width: 100%;
      justify-content: center;
    }
  }
`;
