import { WarningFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { transferMethodsInWords } from "../../utils/reuseableUtils";
import { Colors } from "../../utils/stylesVariables";
import { formatNumberWithCommas } from "../app-getQuote/GetQuoteHelper";
import { getRecipientById } from "./paymentHelper";

interface ConfirmNewRecipientProps {
  showModal?: boolean;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  transfer: any;
  onConfirm?: () => void;
  isUpdating?: boolean;
}

const ConfirmNewRecipient = ({
  transfer,
  onConfirm,
  showModal,
  setShowModal,
  isUpdating,
}: ConfirmNewRecipientProps) => {
  const { recipientId, destinationCurrency, destinationAmount } =
    transfer || {};
  const recipientData = getRecipientById(recipientId);

  const {
    firstName,
    lastName,
    transferMethod,
    mobileMoneyProvider,
    mobile,
    pickupPoint,
    bankName,
    accountNumber,
    recipientAccountNumber,
  } = recipientData?.profile || {};

  const accountNo = accountNumber || recipientAccountNumber || "--";
  const name = `${firstName || "-"} ${lastName || "-"}`;
  const bank = bankName || "--";

  const theyGet = `${destinationCurrency} ${formatNumberWithCommas(
    destinationAmount
  )}`;
  const method = transferMethodsInWords[transferMethod]?.toLowerCase() || "--";
  const mobileProvider = mobileMoneyProvider || "--";
  const mobileNo = mobile || "--";
  const pickupPt = pickupPoint || "--";

  if (!showModal) {
    return <></>;
  }

  return (
    <Modal
      open={showModal}
      footer={null}
      width={400}
      onCancel={() => setShowModal?.(false)}
    >
      <Content>
        <WarningFilled rev={undefined} className="_icon" />

        <h2>Confirm New Recipient</h2>
        <Message>
          This is the first time you are sending money to {name}.{" "}
          <span>
            Kindly double check the recipient details before you proceed
          </span>
          . Please note that successful transfers cannot be reversed.
        </Message>

        <RecipientInfo
          name={name}
          accountNo={accountNo}
          bank={bank}
          theyGet={theyGet}
          transferMethod={method}
          mobile={mobileNo}
          pickupPoint={pickupPt}
          mobileMoneyProvider={mobileProvider}
        />

        <Button
          size="large"
          type="primary"
          onClick={onConfirm}
          loading={isUpdating}
        >
          Yes, I Confirm the Details
        </Button>
      </Content>
    </Modal>
  );
};

interface RecipientInfoProps {
  name?: string;
  accountNo?: string;
  bank?: string;
  theyGet?: string;
  transferMethod?: string;
  pickupPoint?: string;
  mobile?: string;
  mobileMoneyProvider?: string;
}

const RecipientInfo = ({
  name,
  accountNo,
  theyGet,
  bank,
  transferMethod,
  mobileMoneyProvider,
  mobile,
  pickupPoint,
}: RecipientInfoProps) => {
  return (
    <RecipientInfoWrapper>
      <p className="_title">Recipient details</p>

      <div className="_items">
        <RecipientInfoItem>
          <p className="_label">Name</p>
          <p className="_value">{name}</p>
        </RecipientInfoItem>

        {transferMethod === "mobile_money" && (
          <>
            <RecipientInfoItem>
              <p className="_label">Network Pro.</p>
              <p className="_value">{mobileMoneyProvider}</p>
            </RecipientInfoItem>

            <RecipientInfoItem>
              <p className="_label">Mobile No.</p>
              <p className="_value">{mobile}</p>
            </RecipientInfoItem>
          </>
        )}

        {transferMethod === "cash_pickup" && (
          <>
            <RecipientInfoItem>
              <p className="_label">Pickup PT.</p>
              <p className="_value">{pickupPoint}</p>
            </RecipientInfoItem>

            <RecipientInfoItem>
              <p className="_label">Mobile No.</p>
              <p className="_value">{mobile}</p>
            </RecipientInfoItem>
          </>
        )}

        {transferMethod === "bank_transfer" && (
          <>
            <RecipientInfoItem>
              <p className="_label">Account No.</p>
              <p className="_value">{accountNo}</p>
            </RecipientInfoItem>

            <RecipientInfoItem>
              <p className="_label">Bank</p>
              <p className="_value">{bank}</p>
            </RecipientInfoItem>
          </>
        )}

        <RecipientInfoItem>
          <p className="_label">They Get</p>
          <p className="_value">{theyGet}</p>
        </RecipientInfoItem>
      </div>
    </RecipientInfoWrapper>
  );
};

export default ConfirmNewRecipient;

const Content = styled.div`
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ._icon {
    font-size: 60px;
    color: #faad14;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: auto;
    text-align: center;
    margin: 0;
    color: ${Colors.textColor};
  }

  Button {
    width: 100%;
  }
`;

const Message = styled.p`
  width: 100%;
  text-align: center;
  margin: 0;

  color: ${Colors.textColor3};

  span {
    color: ${Colors.sbGreen};
    font-weight: 600;
  }
`;

const RecipientInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 100%;
  height: auto;

  background: #fafafa;
  border-radius: 12px;

  ._title {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin: 0;

    color: ${Colors.textColor};
  }
  ._items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`;

const RecipientInfoItem = styled.div`
  width: 100%;
  gap: 28px;
  display: flex;
  justify-content: space-between;

  ._label {
    margin: 0;
    font-size: 14px;
    color: ${Colors.textColor2};
    flex-shrink: 0;
  }
  ._value {
    margin: 0;
    font-size: 14px;
    color: ${Colors.textColor};
    font-weight: 500;
    text-align: right;
  }
`;
