import { Button } from "antd";
import { Colors } from "components/pages/transcations-flow/utils/stylesVariables";
import styled, { css } from "styled-components";
import { convertDateAndTimeString } from "../../app-transactions/TransactionHelper";

type NotiState = "read" | "unread";

interface NotificationItemProps {
  id: string;
  title: string;
  message: string;
  state?: NotiState;
  date?: string;
  loading?: boolean;
  onReadClick?: (id: string) => void;
}

export const NotificationItem = ({
  id,
  title,
  message,
  state = "read",
  date,
  onReadClick,
  loading,
}: NotificationItemProps) => {
  return (
    <NotificationItemWrapper>
      <ContentWrapper>
        <div className="_header">
          <NotiDot $state={state} />
          <p>{title}</p>
        </div>

        <p>{message}</p>
      </ContentWrapper>
      <FooterWrapper>
        <p>{convertDateAndTimeString(Number(date))}</p>
        {state === "unread" ? (
          <Button loading={loading} onClick={() => onReadClick?.(id)}>
            Mark as read
          </Button>
        ) : (
          <>--</>
        )}
      </FooterWrapper>
    </NotificationItemWrapper>
  );
};

const NotificationItemWrapper = styled.div`
  width: 100%;
  max-width: 750px;
  border-radius: 8px;
  border: 1px solid ${Colors.borderColor};
  overflow: hidden;
  background-color: ${Colors.bgwhite};

  * p {
    margin: 0;
  }
`;

const NotiDot = styled.div<{ $state: NotiState }>`
  width: 8px;
  height: 8px;
  display: flex;
  flex-shrink: 0;
  border-radius: 999px;

  ${(props) =>
    props.$state === "unread"
      ? css`
          background-color: ${Colors.sbGreen};
        `
      : css`
          background-color: ${Colors.borderColor2};
        `}
`;

const ContentWrapper = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  ._header {
    display: flex;
    align-items: center;
    gap: 6px;

    p {
      font-weight: 600;
    }
  }
`;

const FooterWrapper = styled.div`
  background-color: #fafafa;
  border-top: 1px solid ${Colors.borderColor};
  padding: 6px 24px;
  height: 48px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;
