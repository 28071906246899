import { Route, Switch } from "react-router-dom";
import { paths } from "util/paths";
import AxcessMerchant from "../app-components/AxcessPaymentForm";
import Notifications from "../app-pages/app-notification/Notifications";
import Dashboard from "../app-pages/app-dashboard/Dashboard";
import GetQuote from "../app-pages/app-getQuote/GetQuote";
import Pay from "../app-pages/app-pay-methods/PayMethods";
import PaymentComplete from "../app-pages/app-payment-complete/PaymentComplete";
import Profile from "../app-pages/app-profile/Profile";
import Recipients from "../app-pages/app-recipients/Recipients";
import Referrals from "../app-pages/app-referral/Referrals";
import Review from "../app-pages/app-review/Review";
import Settings from "../app-pages/app-settings/Settings";
import Transcations from "../app-pages/app-transactions/Transcations";
import Verifications from "../app-pages/app-verifications/Verifications";

export default function RouteConfig() {
  return (
    <Switch>
      <Route path={paths.GET_QUOTE} component={GetQuote} />
      <Route path={paths.RECIPIENT} component={Recipients} />
      <Route path={paths.REVIEW} component={Review} />
      <Route path={paths.PAYMENT_METHOD} component={Pay} />
      <Route path={paths.AXCESS_MERCHANT} component={AxcessMerchant} />
      <Route path={paths.TRANSFER_COMPLETE_AUTH} component={PaymentComplete} />
      <Route path={paths.NOTIFICATIONS} component={Notifications} />

      <Route path={paths.TRANSACTIONS} component={Transcations} />
      <Route path={paths.REWARDS} component={Referrals} />
      <Route path={paths.DASHBOARD} component={Dashboard} />
      <Route path={paths.PROFILE} component={Profile} />
      <Route path={paths.USER_SETTINGS} component={Settings} />
      <Route path={paths.VERIFICATION} component={Verifications} />
    </Switch>
  );
}
