import { UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Space, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { paths } from "util/paths";
import { useRecipientsData } from "../../app-layout/appLayoutHelper";
import { TableSearch } from "../../app-layout/components/searchBar/DateSelectorAndSearchBar";
import { PageTitileAndDescription } from "../../utils/ReusablePageContent";
import { formatAmount, getFirstLetter } from "../../utils/reuseableUtils";
import { Title } from "../app-dashboard/DashboardSyles";
import { DownloadStatement } from "./AccountStatement";
import { TransactionDetail } from "./TransactionDetail";
import {
  checkForNonTerminalTransactionStatus,
  formatTransactionStatus,
  formatTransactionsReversed,
  thisRecipient,
  useTransactionsData,
} from "./TransactionHelper";
import {
  RecipientName,
  TransactionIdStyles,
  TransactionsContainerStyles,
  TransactionsContentStyles,
  TranscationsTableHeader,
} from "./TranscationStyles";

interface TableDataType {
  key: React.Key;
  recipient: string;
  date: string;
  amount_paid: string;
  amount_received: string;
  status: string;
  action: any;
}

interface TranscationsProps {
  page: "dashboard" | "transactions";
}

export default function Transcations({ page }: TranscationsProps) {
  const user = useSelector((state: any) => state.auth.user);
  const transactions = useSelector((state: any) => state.transactions);
  const { transactionsArray, total, limit } = transactions || {};
  const [offset, setOffset] = useState(0);
  const isTranscationsHasNonTerminalStatus =
    checkForNonTerminalTransactionStatus(transactionsArray);

  const { isFetched: isRecipientsFetched, isError: isRecipientsError } =
    useRecipientsData(user?.id);
  const {
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
  } = useTransactionsData(
    user?.id,
    isRecipientsFetched || isRecipientsError,
    isTranscationsHasNonTerminalStatus,
    offset
  );

  const isLoadingOrFetchingTransactions =
    isLoadingTransactions || isFetchingTransactions;

  const TotalTransactions = !!total ? total : 0;

  const recipients = useSelector((state: any) => state.recipients.recipients);
  const history = useHistory();
  const [openTransactionDetail, setOpenTransactionDetail] = useState(false);
  const [currentTransactionDetail, setCurrentTransactionDetail] =
    useState<any>();
  const [downloadState, setDownloadState] = useState(false);

  const generateColumns = (recipients: any) => {
    const columns: ColumnsType<TableDataType> = [
      {
        title: "Recipient",
        dataIndex: "recipient",
        render: (recipient) => {
          return (
            <Space>
              <Avatar icon={<UserOutlined rev={undefined} />}>
                {getFirstLetter(
                  thisRecipient(recipients, recipient.recipientId)?.firstName
                )}
                {getFirstLetter(
                  thisRecipient(recipients, recipient.recipientId)?.lastName
                )}
              </Avatar>
              <Space direction="vertical" size={0}>
                <RecipientName>
                  {`${
                    thisRecipient(recipients, recipient.recipientId)?.firstName
                  } ${
                    thisRecipient(recipients, recipient.recipientId)?.lastName
                  }`}
                </RecipientName>
                <TransactionIdStyles>
                  SBR{recipient.meta.transactionId}
                </TransactionIdStyles>
              </Space>
            </Space>
          );
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        ellipsis: true,
      },
      {
        title: "Base currency",
        dataIndex: "amount_paid",
        render: (data) => {
          const isReferralDiscount = Boolean(data?.meta?.referralDiscount);
          const isPromoDiscount = Boolean(data?.meta?.promoDiscount);
          return (
            <Space direction="vertical" size={0}>
              <RecipientName>
                {data?.originCurrency} {formatAmount(data?.originAmount)}
              </RecipientName>
              {isReferralDiscount ? (
                <TransactionIdStyles>
                  Referral Bonus Applied 🎁
                </TransactionIdStyles>
              ) : (
                ""
              )}

              {isPromoDiscount ? (
                <TransactionIdStyles>
                  Loyalty Bonus Applied 🎁
                </TransactionIdStyles>
              ) : (
                ""
              )}
            </Space>
          );
        },
      },
      {
        title: "Target currency",
        dataIndex: "amount_received",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status) => {
          return (
            <Space>
              <Badge
                color={formatTransactionStatus(status).color}
                text={formatTransactionStatus(status).text}
              />
            </Space>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "right",
        render: (action) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setOpenTransactionDetail(true);
                  setCurrentTransactionDetail(action);
                }}
              >
                View more
              </Button>
            </Space>
          );
        },
      },
    ];

    return columns;
  };

  const formatedTransactionsForTable = formatTransactionsReversed(
    transactionsArray,
    recipients,
    page
  );

  const columns = generateColumns(recipients);

  const handleTablePaginationChange = (pagination: TablePaginationConfig) => {
    if (pagination && pagination.current) {
      const offset = (pagination.current - 1) * limit;

      setOffset(offset);
    }
  };

  return (
    <TransactionsContainerStyles>
      <DownloadStatement
        open={downloadState}
        setOpen={(state) => setDownloadState(state)}
      />
      {page === "dashboard" ? (
        <></>
      ) : (
        <PageTitileAndDescription
          title="Transaction History 🕒"
          description="View, manage and analyze all the transactions you've created."
        />
      )}
      <TransactionsContentStyles>
        <TranscationsTableHeader>
          <Title>
            {page === "dashboard" ? "Transactions" : "Total"} (
            {TotalTransactions})
          </Title>
          {page === "dashboard" ? (
            <Button
              onClick={() => {
                history.push(paths.TRANSACTIONS);
              }}
            >
              View all
            </Button>
          ) : (
            <Space>
              <TableSearch />
              <Button onClick={() => setDownloadState(true)}>Download</Button>
              {/* <DateToDayConverter /> */}
            </Space>
          )}
        </TranscationsTableHeader>

        <Table
          loading={isLoadingOrFetchingTransactions}
          onChange={handleTablePaginationChange}
          columns={columns}
          dataSource={formatedTransactionsForTable}
          pagination={{
            position: ["bottomCenter"],
            //pageSize: 10,
            showSizeChanger: false,
            total: TotalTransactions,
          }}
          scroll={{ x: 1200 }}
        />
      </TransactionsContentStyles>

      {currentTransactionDetail && (
        <TransactionDetail
          open={openTransactionDetail}
          setOpen={setOpenTransactionDetail}
          transaction={currentTransactionDetail}
        />
      )}
    </TransactionsContainerStyles>
  );
}
