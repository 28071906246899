import { Button, DatePicker, Divider, Form, Modal, Space } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toastAction } from "redux/actions/actions";
import { getRequest } from "util/http";
import {
  downloadStatementPdf,
  formatTransactionsReversed,
} from "./TransactionHelper";

const DATE_FORMAT = "DD-MM-YYYY";

interface AccountStatementProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const DownloadStatement = ({ open, setOpen }: AccountStatementProps) => {
  const [form] = Form.useForm();
  const user = useSelector((state: any) => state.auth.user);
  const recipients = useSelector((state: any) => state.recipients.recipients);
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    const startTimestamp = values.start_date.unix().toString();
    const endTimestamp = values.end_date.unix().toString();

    handleDownloadStatement(startTimestamp, endTimestamp);
  };

  const handleDownloadStatement = async (
    startTimestamp: number,
    endTimestamp: number
  ) => {
    setLoading(true);
    const limit = 8000;
    const offset = 0;

    const endpoint = `/user/${user?.id}/transfers?limit=${limit}&offset=${offset}&dateFrom=${startTimestamp}&dateTo=${endTimestamp}`;

    getRequest(endpoint, "Failed to fetch transactions")
      .then((data) => {
        const transcations = formatTransactionsReversed(
          data?.collections,
          recipients,
          "account_statement"
        );

        if (transcations.length === 0) {
          toastAction({
            show: true,
            type: "error",
            message: "No transactions found for the selected date range.",
          });
          return;
        }

        downloadStatementPdf(user, transcations, recipients).then(() => {
          setOpen(false);
          toastAction({
            show: true,
            type: "success",
            message: `Statement downloaded successfully.`,
          });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateEndDate = async (_: any, endDate: dayjs.Dayjs) => {
    const startDate = form.getFieldValue("start_date");
    if (endDate && startDate && endDate.isBefore(startDate, "day")) {
      return Promise.reject(
        new Error("End date cannot be before the start date.")
      );
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title="Download Statement"
      open={open}
      width={450}
      okText="Download"
      footer={
        <Space>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Download
          </Button>
        </Space>
      }
      onCancel={() => setOpen(false)}
    >
      <Divider style={{ margin: 0 }} />
      <Form
        layout="vertical"
        style={{ paddingBlock: "20px" }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Start Date"
          name="start_date"
          rules={[{ required: true, message: "Please input start date!" }]}
        >
          <DatePicker
            format={DATE_FORMAT}
            size="large"
            style={{ width: "100%" }}
            placeholder="Select start date"
          />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="end_date"
          rules={[
            { required: true, message: "Please select the end date!" },
            { validator: validateEndDate },
          ]}
        >
          <DatePicker
            size="large"
            style={{ width: "100%" }}
            format={DATE_FORMAT}
            placeholder="Select end date"
          />
        </Form.Item>

        <i style={{ color: "gray" }}>
          When you click "Download," your account statement (PDF) will
          automatically download to your device.
        </i>
      </Form>
      <Divider style={{ margin: 0 }} />
    </Modal>
  );
};
